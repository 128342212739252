<template>
	<div class="d_s_detail">
		<div class="tformWarp">
			<div class="topName">
				<span class="name">{{htmlDatas.deviceName}}</span>
				<template v-if='htmlDatas.isSmartDevice && htmlDatas.isSmartDevice=="Y"'>
					<span class="lbs" >智能</span>
					<span class="lbs" v-if="deviceStatuStr" :class="deviceClass">{{deviceStatuStr}}</span>
				</template>
			</div>
			<div class="lbsWarp">
				<span class="itlb" v-for="(it,dex) in htmlDatas.tagList" :key='dex'>{{it}}</span>
			</div>
			<div class="txForm">
				<TForm  ref="htmlFrom" :model="htmlDatas" :formlist="formlist" label-width='88px'></TForm>
			</div>
			
		</div>
		
		<div class="tyleTables">
			<el-tabs v-model="activeTab" @tab-click="tabsClick" >
		    	<el-tab-pane v-for='(it,dex) in tabsArrs' :key='it.val' :disabled='tbloading' :label="getTabName(it)" :name="it.val">
		    		<div style="padding-bottom: 12px;" v-if="it.val==1&&deviceTbs.length>0">
		    			{{deviceTbs[0].description}}-{{deviceTbs[0].deviceName}}  
		    			<el-button type="text" @click='it.isOpen=!it.isOpen'>
							<span v-if="it.isOpen"> 收起</span>
							<span v-else> 展开</span>
						</el-button>
		    		</div>
		    		<!--恶心人的需求  -->
		    		<div class="table-body" v-show="it.isOpen">
						<TTable ref="myTable" :tbloading='tbloading' :tableData="tableData" serialNumber :tableTitle="it.tableTitle" :tbParams="tableParams" :maxHeight='620'>
							<template slot='operating' slot-scope="scope" v-if="it.val==1">
								<el-button type="text" @click.stop="tbRowHistory(scope.row,0)" >历史数据</el-button>
							</template>
							
							<template slot='alarmId' slot-scope="scope" >
								<el-button type="text" @click.stop="toAlarmDeatil(scope.row)" >{{scope.row.alarmUUID}}</el-button>
							</template>
							
							<template slot='inspectionJobCode' slot-scope="scope" >
								<el-button type="text" @click.stop="toInspectionDetail(scope.row)" >{{scope.row.inspectionJobCode}}</el-button>
							</template>
							
							
							<template slot='orderNumber' slot-scope="scope" >
								<el-button type="text" @click.stop="toOrderDetail(scope.row)" >{{scope.row.orderCode}}</el-button>
							</template>
							
							<template slot='orderNumberList' slot-scope="scope" >
								<el-button type='text' v-for='(it,dex) in scope.row.orderList' @click.stop='toOrderDetail(it)'>
	            					{{it.orderCode}};
	            				</el-button>
							</template>
						</TTable>
					</div>
		    </el-tab-pane>
		  </el-tabs>
		</div>
		<div>
			<div class="table-body" v-if="activeTab=='1'">
				<div v-for="(it,dex) in deviceTbs" :key='dex' class="it_d_tb" v-if="dex>0">
					<div>
						<span>{{it.description}}-{{it.deviceName}} </span>
						<el-button type="text" @click='it.isOpen=!it.isOpen'>
							<span v-if="it.isOpen"> 收起</span>
							<span v-else> 展开</span>
						</el-button>
					</div>
					<div v-show="it.isOpen">
						<TTable ref="myTable" :tbloading='tbloading' :tableData="it.rows" serialNumber :tableTitle="tabsArrs[0].tableTitle" :tbParams="tableParams" :maxHeight='440'>
							<template slot='operating' slot-scope="scope" >
								<el-button type="text" @click.stop="tbRowHistory(scope.row,dex)" >历史数据</el-button>
							</template>
						</TTable>
						
					</div>
					</div>
			
			</div>
		</div>
		
		<TPagination v-if='activeTab>1' v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
		<!--查看历史数据抽屉-->
	    <el-dialog width="1100px" top="50px" :visible.sync="openHistorys">
	      <lookHistorys
	        v-if="openHistorys"
	        :productKey="historyObj.productKey"
	        :deviceName="historyObj.deviceName"
	        :moduleName="historyObj.moduleName"
	        :propertyName="historyObj.propertyName"
	        :factoryCode="htmlDatas.factoryCode"
	        :description="historyObj.description"/>
	    </el-dialog>
	
	</div>
</template>

<script>
	import {getProjectListPage} from "@/api/business/base/tenant/project";
	import {getDevicePage} from "@/api/business/base/tenant/device";
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TSeach from '@/components/YTable/TSeach.vue';
	import TTable from '@/components/YTable/TTable.vue';
	import TForm from '@/components/YTable/TForm.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import Pagination from "@/components/Pagination";
	import handsRlink from "@/mixins/handsRlink.js";
	import lookHistorys from "@/views/business/base/device/tenant/lookHistorys.vue";
	const seachArr = [
    	{label:"ychDemo.textName1",type:"text",bindKeys:"name",defaultValue:"123",},
    	{label:"ychDemo.selectName",type:"select",bindKeys:"options",defaultValue:"",options:[]},
	]
	const tableTitle = [
			{name:'设备名称',label:'ychDemo.deviceName',prop:"deviceName",},
	    {name:'操作',label:'commons.actions',width:"180",fixed:'right',slotName:"operating",},
	];
	export default {
		mixins: [pagination,seachAndTable,handsRlink],
		components: {TTable,TSeach,Pagination,TPagination,TForm,lookHistorys},
		props: {
			datas: {
				type: Object,
				default: () => {
					return {
						rlinkDeviceName:''
					}
				},
			},
			colNum:{
				type: Number,
				default: 12,
			},
		},
		watch: {
			datas: {
				deep: true, // 深度监听
				handler(val, oval) {
					console.log('datas', val);
					this.initHtmlDatas();
				}
			},
		},
	  	data() {
	  		return{
	  			htmlDatas:{
	  				isSmartDevice:'N',//是否智能设备
	  				deviceName:'',//设备名称
	  				productKey:'',
	  				deviceNamesObj:{},//设备类型的名称
	  				tagList:[],//标签
	  			},
	  			deviceStatuStr:'',
	  			deviceClass:'',//设备状态颜色的类
	  			urlArrs:[
	  				{name:'物联网实时数据',method:this.getDeviceLsitInfo},
	  				{name:'告警记录',method:this.initAlarmlist},
	  				{name:'工单记录',method:this.initOrderRecordlist},
	  				{name:'巡检记录',method:this.findPatrolPage},
	  				{name:'附属配件',method:this.findConditionalQuery},
	  				{name:'维保记录',method:this.findDeviceMaintenanceList},
	  			],
	  			tabsInit:0,
	  			formlist:[
	  				{name: '所属项目',keys: 'projectName',value: '',type: 'text',flex:12},
	  				{name: '所属系统',keys: 'categoryName',value: '',type: 'text',flex:12},
	  				{name: '设备位置',keys: 'descName',value: '',type: 'text',flex:12},
	  				{name: '设备编码',keys: 'factoryCode',value: '',type: 'text',flex:12},
	  				{name: '设备类型',keys: 'deviceTypeName',value: '',type: 'text',flex:12},
	  				{name: '责任部门',keys: 'dutyDeptName',value: '',type: 'text',flex:12},
	  				{name: '责任人',keys: 'repairPersonName',value: '',type: 'text',flex:12},
//	  				{name: '产品标识码',keys: 'productKey',value: '',type: 'text',flex:12,isHide:false},
//	  				{name: '设备标识码',keys: 'rlinkDeviceName',value: '',type: 'text',flex:12,isHide:false},
	  				{name: '关联企业',keys: 'enterpriseName',value: '',type: 'text',flex:12},
	  				{name: '维修工时',keys: 'workTime',value: '',type: 'text',flex:12},
	  				{name: '更新时间',keys: 'lastUpdateDateStr',value: '',type: 'text',flex:12},
	  				{name: '更新人',keys: 'lastUpdatedByName',value: '',type: 'text',flex:12},
	  				{name: '创建时间',keys: 'creationDateStr',value: '',type: 'text',flex:12},
	  			],
	  			activeTab: '-1',
	  			isSmart:true,//是否智能设备
	  			//普通设备tabs
	  			ordinaryDevice:[
		  			{name:'工单记录',num:0,val:'3',isOpen:true,tableTitle:[
	  					{name:'工单编号',slotName:"orderNumber"},
	  					{name:'问题描述',prop:"problemDesc"},
	  					{name:'工单模版',prop:"configName"},
	  					{name:'责任部门',prop:"resDeptName"},
	  					{name:'维修人员',prop:"repairPeople"},
	  					{name:'报单部门',prop:"submitUserDeptName"},
	  					{name:'报单人员',prop:"submitUserName"},
	  					{name:'工单状态',prop:"orderStatusStr"},
	  					{name:'报单时间',prop:"creationDateStr"},
	  				]},
	  				{name:'巡检记录',num:0,val:'4',isOpen:true,tableTitle:[
	  					{name:'巡检编号',slotName:"inspectionJobCode" },
	  					{name:'巡检名称',prop:"inspectionJobName"},
	  					{name:'责任部门',prop:"deptName"},
	  					{name:'执行岗位',prop:"jobDutyListStr"},
	  					{name:'执行人员',prop:"handlePersonName"},
	  					{name:'巡检 状态',prop:"statusName"},
	  					{name:'开始时间',prop:"startTimeStr"},
	  					{name:'结束时间',prop:"endTimeStr"},
	  				]},
	  				{name:'附属配件',num:0,val:'5',isOpen:true,tableTitle:[
	  					{name:'物料编码',slotName:"materielCode" },
	  					{name:'物料名称',prop:"materielName"},
	  					{name:'规格型号',prop:"model"},
	  					{name:'出库时间',prop:"auditTimeStr"},
	  					{name:'说明',prop:"description"},
	  				]},
	  				{name:'维保记录',num:0,val:'6',isOpen:true,tableTitle:[
	  					{name:'任务编码',slotName:"inspectionJobCode" },
	  					{name:'任务名称',prop:"inspectionJobName"},
	  					{name:'责任部门',prop:"deptName"},
	  					{name:'责任人',prop:"handlePersonName"},
	  					{name:'任务开始时间',prop:"startTimeStr"},
	  					{name:'任务结束时间',prop:"endTimeStr"},
	  				]},
	  			
	  			],
	  			//智能设备tabs
	  			intellectDevice:[
	  				{name:'物联网实时数据',num:0,val:'1',isOpen:true,tableTitle:[
	  					{name:'参数名称',prop:"description"},
	  					{name:'上报值',prop:"valueStr"},
	  					{name:'上报时间',prop:"updateTimeStr"},
	  					{name:'操作',prop:"keys4",slotName:"operating"},
	  				]},
	  				{name:'告警记录',num:0,val:'2',isOpen:true,tableTitle:[
	  					{name:'告警ID',slotName:"alarmId",width:'280'},
	  					{name:'告警状态',prop:"alarmStatusStr"},
	  					{name:'设备标识码',prop:"deviceName"},
	  					{name:'设备参数',prop:"propertyDescription"},
	  					{name:'告警值',prop:"valUnit"},
	  					{name:'告警级别',prop:"alarmLevelStr"},
	  					{name:'告警类型',prop:"alarmTypeStr"},
	  					{name:'告警时间',prop:"alarmTimestampStr"},
	  					{name:'告警工单编号',slotName:"orderNumberList"},
	  				]},
	  			],
	  			tabsArrs:[],
	  			deviceTbs:[],
	  			openHistorys:false,
	  			historyObj:{
	  				//设备参数列表  查看历史记录所需数据
	  				isOpen:false,
	  				productKey:'',
			        deviceName:'',
			        moduleName:'',
			        propertyName:'',
			        description:'',
			        currentDataType:'',
	  			},
	  		}
	  	},
	  	created() {
	  		this.initHtmlDatas();
	  	},
	  	methods: {
	  		
	  		getTabName(it){
	  			if(it.val=='1'){
	  				return it.num?it.name+'('+(this.deviceTbs.length)+')':it.name
	  			}else{
	  				return it.num?it.name+'('+it.num+')':it.name
	  			}
	  			
	  			
	  		},
	  		
	  		resetTabsItem(){
	  			if(this.deviceTbs.length>0){	
		  			this.tabsArrs=this.intellectDevice.concat(this.ordinaryDevice);
		  			this.setHideFromItems(false)
		  		}else{
		  			this.tabsArrs=this.ordinaryDevice;
		  			this.setHideFromItems(true)
		  		}
		  		this.tabsArrs.map(it=>it.num=0);
		  		this.activeTab=this.tabsArrs[0].val
				this.resetTFrom();
	  		},
	  		async initHtmlDatas(){
	  			this.tabsArrs=[];
//	  			this.tabsArrs=this.intellectDevice.concat(this.ordinaryDevice);
//	  			if(this.deviceTbs.length>0){
//		  			this.tabsArrs=this.intellectDevice.concat(this.ordinaryDevice);
//		  			this.setHideFromItems(false)
//		  		}else{
//		  			this.tabsArrs=this.ordinaryDevice;
//		  			this.setHideFromItems(true)
//		  		}
//		  		this.tabsArrs.map(it=>it.num=0);
//		  		this.activeTab=this.tabsArrs[0].val
//				this.resetTFrom();
				await this.getDetailById();
				await this.initTable(); 
//				this.tabsArrs.map(it =>{
//					this.tabsInit++;
//					this.initTable(it.val)
//				} );
				
				for(let it of this.tabsArrs){
					this.tabsInit++;
					let res=await this.initTable(it.val)
				}
				
	  		},
	  		setHideFromItems(isHide){
	  			let hideKeys=['productKey','rlinkDeviceName'];
	  			for(let item of this.formlist) {
	  				if(hideKeys.indexOf(item.keys)>-1){
	  					item.isHide=isHide;
	  				}
				}
	  			
	  		},
	  		resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		async getDetailById(){
	  			if(!this.datas.deviceId) return;
	  			let res = await this.getDeviceBasicsInfo(this.datas.deviceId);
				if(res){
					res.deviceTypeName=this.datas.deviceNamesObj[res.deviceType];
					this.deviceTbs=res.deviceArrs||[];
					this.resetTabsItem();
					this.htmlDatas={...this.htmlDatas,...res};
					console.log('获取详细',this.htmlDatas,res) 
					console.log('this.deviceTbs',this.deviceTbs.length) 
				}
	  			
	  		},
	  		
	  		
	  		
	  		toAlarmDeatil(row){
	  			console.log(row,'132123');
	  			const routeData = this.$router.resolve({
	              path: '/iot/alarmRecords/detail',
	              query: {
	                rowId: row.alarmUUID,
	              }
	            })
	            window.open(routeData.href, '_blank')
//	  			this.$router.push('/?rowId=9a25795cff694e66a991108effdce670')
	  		},
	  		toOrderDetail(row){
	  			let params = {
		        path: "/iot/orderQuery/detail",
		        query: {
		          orderId: row.orderId,
		        },
	      	};
	      	this.$router.push(params);
	  			
//	  			const routeData = this.$router.resolve({
//	              	path: '/iot/orderQuery/detail',
//					query: {
//						orderId: row.orderId,
//					}
//	            })
//	            window.open(routeData.href, '_blank')
	  			
	  		},
	  		toInspectionDetail(row){
	  			const routeData = this.$router.resolve({
	              	path: '/tenant/inspection/inspectionSearch/list',
					query: {
						inspectionJobId: row.inspectionJobId,
					}
	            })
	            window.open(routeData.href, '_blank')
	  			
	  		},
	  		tbRowHistory(row,dex){
	  			//查看历史记录
	  			const items=this.deviceTbs[dex];
	  			console.log(row,dex,'tbRowHistory')
	  			this.historyObj={
	  				...row,
	  				productKey:items.productKey,
	        		deviceName:items.deviceName,
	  			};
	  			this.openHistorys=true;
	  			
	  		},
	  		tabsClick(val){
	  			if(this.tbloading){
	  				this.$message('数据加载中')
	  				return;
	  			}
	  			this.tableParams.current=1;
	  			this.initTable();
	  			console.log('111',val,this.tableParams)
	  		},
	  		getDeviceLsitInfo(){
	  			let res={
	  				rows:[],
	  				total:0,
	  			};
	  			let len=this.deviceTbs.length;
	  			//这里的总数 与其他3个表的总数不一样， 其他几个表格的num字段是显示总条数，这个是显示个数
	  			if(this.deviceTbs&&len>0){
	  				let Arr=this.deviceTbs[0];
	  				res={
	  					rows:Arr.rows,
	  					total:len,
	  				};
	  			}
	  			return res
	  		},
	  		async initTable(tbNum){
	  			//tbNum 当指定类型的时候  去获取各类型的数量  但是不赋值
	  			let tbVal=tbNum ? Number(tbNum) : Number(this.activeTab);
	  			if(!tbNum){
	  				this.tableData=[];
	  			}
	  			this.tbloading=true;
	  			let index=(tbVal-1);
	  			let reqMethod=this.urlArrs[index].method;
	  			let params = {
					...this.seachData,
					...this.tableParams,
					deviceId:this.datas.deviceId,
					relationId:this.datas.deviceId,
					deviceCode:this.datas.factoryCode,
				}
	  			if(tbVal<3){
					params.productKey =this.datas.productKey;
					params.rlinkDeviceName =this.datas.rlinkDeviceName;
					params.deviceName =this.datas.rlinkDeviceName;
					console.log('params',params)
				}
	  			delete params.total
	  			let res=await reqMethod(params);
				if(!tbNum){
					console.log('resresresres',res)
					
					this.tableData = res.rows;
					this.tableParams.total = res.total;
				}else{
					if(tbVal=='1'&&res.deviceStatus){
						this.deviceStatuStr=this.DeviceStatusObj[res.deviceStatus]
						this.deviceClass= res.deviceStatus;
					}
				}
				this.tabsArrs.map(it=>{
	  				if(it.val==tbVal){
  						it.num=res.total;
  					}
	  			})
				if(tbNum){
					if(this.tabsInit>=this.tabsArrs.length){
						this.tbloading=false;
						return;
					}
				}else{
					this.tbloading=false;
				}
	  			
	  		},
	  		
	  		
	  	},
		
	};
	
	
</script>
<style lang="scss">
	.d_s_detail{
		.it_d_tb{
			padding:12px 0;
			
			
		}
		.tformWarp{
			border: 1px solid #DCDFE6;
			padding: 20px;
			padding-bottom: 8PX;
			margin-bottom: 16px;
		}
		.topName{
			.name{
				font-size: 16px;
				font-weight: bold;
				color: #303133;
				line-height: 26px;
			}
			.lbs{
				margin: 0 6px;
				padding: 2px 5px;
				font-size: 12px;
				color: #3880FF;
				border-radius: 2px;
				border: 1px solid #3880FF;
				position: relative;
    			top: -2px;
			}
			.lbs.ONLINE{
				color: #67c23a;
				border: 1px solid #67c23a;
			}
			.lbs.OFFLINE{
				color: #f56c6c;
				border: 1px solid #f56c6c;
			}
		}
		.lbsWarp{
			margin-top: 8px;
			margin-bottom: 4px;
			.itlb{
				background: #ECF5FF;
				border-radius: 2px;
				font-size: 12px;
				color: #409EFF;
				padding: 5px 4px;
				margin-right: 8px;
			}
		}
		
		
	}
</style>
<style >
	.txForm .Y-myFrom .el-form-item{
		margin-bottom: 0;
	}
	.txForm .Y-myFrom .el-form-item__label{
		color: #909399;
	}
</style>