import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 系统列表查询
 */
export function getCategoryList() {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/category/list',
        method: 'get',
    })
}

/**
 * 创建系统分类
 * @param params
 */
export function createCategory(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/category/create',
        method: 'post',
        data : params
    })
}

/**
 * 修改系统分类
 * @param params
 */
export function updateCategory(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/category/update',
        method: 'post',
        data : params
    })
}

/**
 * 删除系统分类
 * @param params
 */
export function deleteCategory(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/category/delete',
        method: 'delete',
        data : params
    })
}

/**
 * 导出系统分类
 * @param params
 */
export function exportCategory() {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/category/export',
        method: 'get',
    })
}
