var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editMonitorDialogVisible,
            width: "50%",
            "before-close": _vm.cancelEditMonitorModal,
            "close-on-click-modal": false,
            top: "6vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editMonitorDialogVisible = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("base.device.monitor")) + "\n    "),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "monitorForm",
              attrs: {
                rules: _vm.monitorFormRules,
                model: _vm.monitor.form,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.monitor.name"),
                            prop: "monitorName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "16",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.monitor.form.monitorName,
                              callback: function ($$v) {
                                _vm.$set(_vm.monitor.form, "monitorName", $$v)
                              },
                              expression: "monitor.form.monitorName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.monitor.monitorType"),
                            prop: "monitorType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { filterable: "" },
                              model: {
                                value: _vm.monitor.form.monitorType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.monitor.form, "monitorType", $$v)
                                },
                                expression: "monitor.form.monitorType",
                              },
                            },
                            _vm._l(_vm.monitorLabel, function (item) {
                              return _c("el-option", {
                                key: item.itemCode,
                                attrs: {
                                  label: item.itemName,
                                  value: item.itemCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.monitor.historyTable"),
                            prop: "historyTable",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "80",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.monitor.form.historyTable,
                              callback: function ($$v) {
                                _vm.$set(_vm.monitor.form, "historyTable", $$v)
                              },
                              expression: "monitor.form.historyTable",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.monitor.industryType"),
                            prop: "industryType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              model: {
                                value: _vm.monitor.form.industryType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.monitor.form,
                                    "industryType",
                                    $$v
                                  )
                                },
                                expression: "monitor.form.industryType",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 2,
                                attrs: {
                                  label: _vm.$t("base.monitor.telemetering"),
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                key: 3,
                                attrs: {
                                  label: _vm.$t("base.monitor.telecommand"),
                                  value: 3,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.monitor.controlSource"),
                            prop: "sourceId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: _vm.$t("commons.selectPlease"),
                              },
                              model: {
                                value: _vm.monitor.form.sourceId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.monitor.form, "sourceId", $$v)
                                },
                                expression: "monitor.form.sourceId",
                              },
                            },
                            _vm._l(_vm.sourceList, function (item) {
                              return _c("el-option", {
                                key: item.sourceId,
                                attrs: {
                                  label: item.sourceName,
                                  value: item.sourceId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.device.monitorPath") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "160",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.monitor.form.monitorPath,
                              callback: function ($$v) {
                                _vm.$set(_vm.monitor.form, "monitorPath", $$v)
                              },
                              expression: "monitor.form.monitorPath",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.monitor.alarmFlag") } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              model: {
                                value: _vm.monitor.form.alarmFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.monitor.form, "alarmFlag", $$v)
                                },
                                expression: "monitor.form.alarmFlag",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 1,
                                attrs: {
                                  label: _vm.$t("commons.yes"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                key: 0,
                                attrs: {
                                  label: _vm.$t("commons.no"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.monitor.isShow") } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              model: {
                                value: _vm.monitor.form.isShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.monitor.form, "isShow", $$v)
                                },
                                expression: "monitor.form.isShow",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 1,
                                attrs: {
                                  label: _vm.$t("commons.yes"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                key: 0,
                                attrs: {
                                  label: _vm.$t("commons.no"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.monitor.desc") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "160",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.monitor.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.monitor.form, "description", $$v)
                              },
                              expression: "monitor.form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.monitor.form.alarmFlag == 1
                ? [
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "center" } },
                      [_vm._v(_vm._s(_vm.$t("alarm.strategy")))]
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("base.monitor.alarmLevel"),
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    style: { width: "100%" },
                                    model: {
                                      value: _vm.monitor.form.monitorLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.monitor.form,
                                          "monitorLevel",
                                          $$v
                                        )
                                      },
                                      expression: "monitor.form.monitorLevel",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: 1,
                                      attrs: {
                                        label: _vm.$t("base.monitor.prompt"),
                                        value: 1,
                                      },
                                    }),
                                    _c("el-option", {
                                      key: 2,
                                      attrs: {
                                        label: _vm.$t("base.monitor.commonly"),
                                        value: 2,
                                      },
                                    }),
                                    _c("el-option", {
                                      key: 3,
                                      attrs: {
                                        label: _vm.$t("base.monitor.serious"),
                                        value: 3,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("base.monitor.autoOrder"),
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    style: { width: "100%" },
                                    model: {
                                      value: _vm.monitor.form.autoOrder,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.monitor.form,
                                          "autoOrder",
                                          $$v
                                        )
                                      },
                                      expression: "monitor.form.autoOrder",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: 1,
                                      attrs: {
                                        label: _vm.$t("commons.yes"),
                                        value: 1,
                                      },
                                    }),
                                    _c("el-option", {
                                      key: 0,
                                      attrs: {
                                        label: _vm.$t("commons.no"),
                                        value: 0,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("alarm.alarmType") } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    style: { width: "100%" },
                                    model: {
                                      value: _vm.monitor.form.alarmType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.monitor.form,
                                          "alarmType",
                                          $$v
                                        )
                                      },
                                      expression: "monitor.form.alarmType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: 1,
                                      attrs: {
                                        label: _vm.$t("alarm.runningAlarm"),
                                        value: 1,
                                      },
                                    }),
                                    _c("el-option", {
                                      key: 2,
                                      attrs: {
                                        label: _vm.$t("alarm.faultAlarm"),
                                        value: 2,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.monitor.form.industryType == 3
                      ? [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { md: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "base.monitor.alarmVoteValue"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "6",
                                          "show-word-limit": true,
                                          placeholder: _vm.$t(
                                            "commons.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.monitor.form.alarmVoteValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.monitor.form,
                                              "alarmVoteValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "monitor.form.alarmVoteValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { md: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "base.monitor.alarmVoteDelay"
                                        ),
                                        prop: "alarmVoteDelay",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "3",
                                          "show-word-limit": true,
                                          placeholder: _vm.$t(
                                            "commons.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.monitor.form.alarmVoteDelay,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.monitor.form,
                                              "alarmVoteDelay",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "monitor.form.alarmVoteDelay",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.monitor.form.industryType == 2
                      ? [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { md: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("base.monitor.lowValue"),
                                        prop: "lowValue",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "3",
                                          "show-word-limit": true,
                                          placeholder: _vm.$t(
                                            "commons.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.monitor.form.lowValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.monitor.form,
                                              "lowValue",
                                              $$v
                                            )
                                          },
                                          expression: "monitor.form.lowValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { md: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "base.monitor.heightValue"
                                        ),
                                        prop: "heightValue",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "3",
                                          "show-word-limit": true,
                                          placeholder: _vm.$t(
                                            "commons.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.monitor.form.heightValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.monitor.form,
                                              "heightValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "monitor.form.heightValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { md: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "base.monitor.toleranceValue"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "3",
                                          "show-word-limit": true,
                                          placeholder: _vm.$t(
                                            "commons.pleaseInput"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.monitor.form.toleranceValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.monitor.form,
                                              "toleranceValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "monitor.form.toleranceValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelEditMonitorModal } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.createLoading,
                      expression: "createLoading",
                      modifiers: { fullscreen: true, lock: true },
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleMonitorSave },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n        ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }