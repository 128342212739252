<template>
    <div>
        <el-row>
            <el-col :span="6">
                <el-tag>{{ deviceRow.categoryName }}/{{ deviceRow.deviceName }}</el-tag>
            </el-col>
            <el-col :span="8" :offset="10">
                <div class="filter-container" align="right">
                    <el-button type="primary"
                               icon="el-icon-plus"
                               size="small"
                               @click="createMonitor"
                               :loading="createLoading">{{$t('base.device.addMonitor')}}
                    </el-button>
                    <el-button type="primary" icon="el-icon-upload" size="small" plain @click="downMonitorTemplate">{{$t('commons.importTemplate')}}</el-button>
                    <el-button type="primary" icon="el-icon-upload2" size="small" plain @click="uploadModel = true">{{$t('commons.import')}}</el-button>
                    <el-button type="primary" icon="el-icon-download" size="small" plain @click="exportMonitor">{{$t('commons.export')}}</el-button>
                </div>
            </el-col>
        </el-row>
        <el-table
            v-loading="monitor.listLoading"
            :data="monitor.list"
            element-loading-text="loading"
            border
            fit
            highlight-current-row
            stripe>
            <el-table-column prop="monitorName" :label="$t('base.monitor.name')" width="180">
                <template slot-scope="scope">
                    <span>{{ scope.row.monitorName }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="monitorType" :label="$t('base.monitor.monitorType')" width="120">
                <template slot-scope="scope">
                    <span>{{ scope.row.monitorTypeName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('base.device.historyTable')" width="180">
                <template slot-scope="scope">
                    <span>{{ scope.row.historyTable }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="industryType" :label="$t('base.monitor.industryType')" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.industryType==2?$t('base.monitor.telemetering'):$t('base.monitor.telecommand') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="alamFlag" :label="$t('base.monitor.alarmFlag')" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.alarmFlag==1?$t('commons.yes'):$t('commons.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="isShow" :label="$t('base.monitor.isShow')" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.isShow==1?$t('commons.yes'):$t('commons.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('base.device.monitorPath')" width="500">
                <template slot-scope="scope">
                    <span>{{ scope.row.monitorPath }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="description" :label="$t('base.monitor.desc')">
                <template slot-scope="scope">
                    <span>{{ scope.row.description }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('commons.actions')" align="center" width="100">
                <template slot-scope="scope">
                    <el-tooltip :content="$t('commons.edit')" placement="bottom">
                        <el-button
                                size="mini"
                                icon="el-icon-edit"
                                type="primary"
                                circle
                                :disabled="scope.row.processing"
                                @click="updateMonitor(scope.row)">
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :content="$t('commons.delete')" placement="bottom">
                        <el-button
                                size="mini"
                                icon="el-icon-delete"
                                type="danger"
                                circle
                                :disabled="scope.row.processing"
                                @click="deleteMonitor(scope.row.monitorId)">
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="monitor.total>0" :total="monitor.total" :page.sync="monitor.listQuery.current"
            :limit.sync="monitor.listQuery.rowCount" @pagination="getMonitorPage"/>

        <template v-if="editMonitorDialogVisible">
            <device-monitor-edit @cancelEditModal="cancelEditMonitorModal" v-bind:monitorRow="monitorRow" :getMonitorPage="getMonitorPage"></device-monitor-edit>
        </template>

        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon/>{{ $t('commons.uploadFile') }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess" @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import DeviceMonitorEdit from '@/views/business/base/device/tenant/deviceMonitorEdit'
import { getMonitorPage, deleteMonitor, exportMonitor, downMonitorTemplate} from '@/api/business/base/tenant/device'
import { envInfo } from '@/constants/envInfo'
import Upload from '@/components/Upload'
import store from '@/store/index'

export default {
    name: "DeviceMonitor",
    components: {
        DeviceMonitorEdit,
        Pagination,
        Upload
    },
    props:{
        deviceRow: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            uploadPath:envInfo.bgApp.archivePath+'/param/archive/upload?ulType=DefaultUpload',
            uploadModel: false,
            uploadType: "ExcelImport",
            excelType: "ruge.monitor",
            rootPath: envInfo.bgApp.basePath,
            createLoading: false,
            editMonitorDialogVisible: false,
            monitor:{
                editMonitorDialogVisible: false,
                cancelEditMonitorModal:false,
                title:null,
                total: 0,
                list:null,
                listLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    deviceId:null,
                    searchPhrase : null,
                }
            },
            monitorRow: null
        }
    },
    created() {
        this.getMonitorPage()
    },
    methods: {
        cancelEditMonitorModal(){
            this.editMonitorDialogVisible = false;
        },
        getMonitorPage(){
            this.monitor.listLoading = true;
            this.monitor.listQuery.deviceId = this.deviceRow.deviceId;
            getMonitorPage(this.monitor.listQuery).then(response =>{
                this.monitor.total = response.total;
                this.monitor.list = response.rows;
                this.monitor.listLoading = false;
            });
        },
        createMonitor(){
            this.monitor.title = this.$t('base.device.addMonitor');
            this.monitorRow = {
                monitorId:null,
                sourceId:null,
                monitorName:null,
                monitorPath:null,
                alarmFlag: 0,
                monitorLevel: 1,
                alarmType: 1,
                autoOrder: 0,
                industryType: null,
                alarmVoteValue: null,
                lowValue: null,
                heightValue: null,
                alarmVateDelay: 0,
                toleranceValue: null,
                deviceId: this.deviceRow.deviceId,
                monitorType:null,
                historyTable:null,
                isShow: 0,
                description:null,
            };
            this.editMonitorDialogVisible = true;
        },
        updateMonitor(row){
            this.monitor.title = this.$t('base.device.editMonitor');
            row.monitorType = row.monitorType +'';
            this.monitorRow = row;
            this.editMonitorDialogVisible = true;
        },
        deleteMonitor(monitorId){
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                confirmButtonText: this.$t('commons.confirm'),
                cancelButtonText: this.$t('commons.cancel'),
                type: 'warning'
            }).then(() => {
                deleteMonitor({"monitorId":monitorId}).then(()=>{
                    // 成功提示
                    this.$message({
                        message: this.$t('message.deleteSuccess'),
                        type: 'success'
                    });
                    this.getMonitorPage();
                });
            }).catch(error => {
                console.log(`未删除，原因 => ${error}`)
            });
        },
        uploadSuccess(){
            //this.uploadModel = false
            this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
        },
        uploadFail() {
            this.$message({type: 'error', message: this.$t('message.uploadFial')});
        },
        exportMonitor(){
            exportMonitor(this.monitor.listQuery).then((msg)=>{
                this.$message({type: 'success', message: this.$t('message.operationSuccess')});
                let exportObj = {
                    taskId : msg,
                    taskName : "Monitor",
                    taskStatus : 0,
                    rootPath : 'basePath'
                };
                //将导出任务丢入导出任务列表中
                store.dispatch('PushExportNotice',exportObj);
            })
        },
        downMonitorTemplate(){
            downMonitorTemplate();
        },
    }
}
</script>
