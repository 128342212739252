<template>
    <div>
    <!--编辑监控点弹框-->
    <el-dialog :visible.sync="editMonitorDialogVisible" width="50%" :before-close="cancelEditMonitorModal" :close-on-click-modal="false" top="6vh">
        <template slot="title">
            <title-icon/>{{ $t('base.device.monitor') }}
        </template>
        <el-form ref="monitorForm" :rules="monitorFormRules" :model="monitor.form" label-width="100px">
            <el-row>
                <el-col :md="12">
                    <el-form-item :label="$t('base.monitor.name')" prop="monitorName">
                        <el-input v-model="monitor.form.monitorName" maxlength="16" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.monitorType')" prop="monitorType">
                        <el-select :style="{width:'100%'}" v-model="monitor.form.monitorType" filterable>
                            <el-option
                                    v-for="item in monitorLabel"
                                    :label="item.itemName"
                                    :value="item.itemCode"
                                    :key="item.itemCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.historyTable')" prop="historyTable">
                        <el-input v-model="monitor.form.historyTable" maxlength="80" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.industryType')" prop="industryType">
                        <el-select :style="{width:'100%'}" v-model="monitor.form.industryType">
                            <el-option :label="$t('base.monitor.telemetering')" :value="2" :key="2"></el-option>
                            <el-option :label="$t('base.monitor.telecommand')" :value="3" :key="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.controlSource')" prop="sourceId">
                        <el-select v-model="monitor.form.sourceId" style="width: 100%" filterable :placeholder="$t('commons.selectPlease')">
                            <el-option v-for="item in sourceList"
                                       :key="item.sourceId"
                                       :label="item.sourceName"
                                       :value="item.sourceId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.device.monitorPath')">
                        <el-input v-model="monitor.form.monitorPath" maxlength="160" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.alarmFlag')">
                        <el-select :style="{width:'100%'}" v-model="monitor.form.alarmFlag">
                            <el-option :label="$t('commons.yes')" :value="1" :key="1"></el-option>
                            <el-option :label="$t('commons.no')" :value="0" :key="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.isShow')">
                        <el-select :style="{width:'100%'}" v-model="monitor.form.isShow">
                            <el-option :label="$t('commons.yes')" :value="1" :key="1"></el-option>
                            <el-option :label="$t('commons.no')" :value="0" :key="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :md="12">
                    <el-form-item  :label="$t('base.monitor.desc')">
                        <el-input v-model="monitor.form.description" maxlength="160" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <template v-if="monitor.form.alarmFlag==1">
                <el-divider content-position="center">{{$t('alarm.strategy')}}</el-divider>
                <el-row>
                    <el-col :md="12">
                        <el-form-item  :label="$t('base.monitor.alarmLevel')">
                            <el-select :style="{width:'100%'}" v-model="monitor.form.monitorLevel">
                                <el-option :label="$t('base.monitor.prompt')" :value="1" :key="1"></el-option>
                                <el-option :label="$t('base.monitor.commonly')" :value="2" :key="2"></el-option>
                                <el-option :label="$t('base.monitor.serious')" :value="3" :key="3"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item  :label="$t('base.monitor.autoOrder')">
                            <el-select :style="{width:'100%'}" v-model="monitor.form.autoOrder">
                                <el-option :label="$t('commons.yes')" :value="1" :key="1"></el-option>
                                <el-option :label="$t('commons.no')" :value="0" :key="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item :label="$t('alarm.alarmType')">
                            <el-select :style="{width:'100%'}" v-model="monitor.form.alarmType">
                                <el-option :label="$t('alarm.runningAlarm')" :value="1" :key="1"></el-option>
                                <el-option :label="$t('alarm.faultAlarm')" :value="2" :key="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <template v-if="monitor.form.industryType==3">
                <el-row>
                    <el-col :md="12">
                        <el-form-item  :label="$t('base.monitor.alarmVoteValue')">
                            <el-input v-model="monitor.form.alarmVoteValue" maxlength="6" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item  :label="$t('base.monitor.alarmVoteDelay')" prop="alarmVoteDelay">
                            <el-input v-model="monitor.form.alarmVoteDelay" maxlength="3" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                </template>

                <template v-if="monitor.form.industryType==2">
                    <el-row>
                        <el-col :md="12">
                            <el-form-item  :label="$t('base.monitor.lowValue')" prop="lowValue">
                                <el-input v-model="monitor.form.lowValue" maxlength="3" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="12">
                            <el-form-item  :label="$t('base.monitor.heightValue')" prop="heightValue">
                                <el-input v-model="monitor.form.heightValue" maxlength="3" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :md="12">
                            <el-form-item  :label="$t('base.monitor.toleranceValue')">
                                <el-input v-model="monitor.form.toleranceValue" maxlength="3" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
            </template>
        </el-form>
        <span slot="footer">
            <el-button @click="cancelEditMonitorModal">{{$t('commons.cancel') }}</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="createLoading" @click="handleMonitorSave">{{$t('commons.save')}}
            </el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
import {getLoopUpItem, findControlSource, createMonitor, updateMonitor  } from '@/api/business/base/tenant/device'
import {validateNumber} from '@/utils/validate'
export default {
    name: "DeviceMonitorEdit",
    props:{
        monitorRow: {
            type: Object,
            required: true
        },
        getMonitorPage:{
            type: Function,
            required: true
        }
    },
    data() {
        return {
            createLoading: false,
            monitorLabel: [],
            sourceList:[],
            editMonitorDialogVisible: true,
            monitor: {
                form: {
                    monitorId:null,
                    sourceId:null,
                    monitorName:null,
                    monitorPath:null,
                    alarmFlag: 0,
                    alarmType: 1,
                    autoOrder: 0,
                    monitorLevel: 1,
                    industryType: 2,
                    alarmVoteValue: null,
                    lowValue: null,
                    heightValue: null,
                    alarmVoteDelay: 0,
                    toleranceValue: null,
                    deviceId:null,
                    deviceEUI: null,
                    monitorType:null,
                    historyTable:null,
                    isShow: 0,
                    description:null,
                }
            },
            monitorFormRules:{
                monitorName: [
                    {
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }
                ],
                monitorType: [
                    {
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }
                ],
                historyTable: [
                    {
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }
                ],
                sourceId: [
                    {
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }
                ],
                industryType: [
                    {
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }
                ],
                lowValue: [{
                    required: false,
                    trigger: ['blur', 'change']
                },{
                    validator: (rule, value, callback) =>{
                        if(value && !validateNumber(value)) {
                            callback(new Error(this.$t('base.monitor.validMessage.number')));
                        }
                        callback();
                    },
                        trigger: ['blur', 'change']
                }],
                heightValue: [{
                    required: false,
                    trigger: ['blur', 'change']
                },{
                    validator: (rule, value, callback) =>{
                        if(value && !validateNumber(value)) {
                            callback(new Error(this.$t('base.monitor.validMessage.number')));
                        }
                        callback();
                    },
                    trigger: ['blur', 'change']
                }],
                alarmVoteDelay: [{
                    required: false,
                    trigger: ['blur', 'change']
                },{
                    validator: (rule, value, callback) =>{
                        if(value && !validateNumber(value)) {
                            callback(new Error(this.$t('base.monitor.validMessage.number')));
                        }
                        callback();
                    },
                    trigger: ['blur', 'change']
                }],
            }
        }
    },
    created() {
        this.getMonitorLabel();
        this.getControlSourceList();
        this.monitorRow.monitorType = this.monitorRow.monitorType;
        this.monitor.form = this.monitorRow;
        this.editMonitorDialogVisible = true;
    },
    methods: {
        getMonitorLabel() {
            getLoopUpItem({"classifyCode":"MONITOR_POINT_TYPE"}).then(res=>{
                this.monitorLabel = res;
            });
        },
        getControlSourceList() {
            findControlSource().then(res=>{
                this.sourceList = res;
            })
        },
        cancelEditMonitorModal() {
            this.$emit('cancelEditModal');
        },
        handleMonitorSave() {
            this.$refs.monitorForm.validate(v=> {
                if (v) {
                    if(this.monitor.form.monitorId == null){
                        createMonitor(this.monitor.form).then(() => {
                            this.$emit('cancelEditModal');
                            // 成功提示
                            this.$message({
                                message: this.$t('message.saveSuccess'),
                                type: 'success'
                            });
                            this.getMonitorPage();
                        }).catch((error) => {
                            console.log(`保存失败，原因 => ${error}`)
                        });
                    }else{
                        updateMonitor(this.monitor.form).then(() => {
                            this.$emit('cancelEditModal');
                            // 成功提示
                            this.$message({
                                message: this.$t('message.saveSuccess'),
                                type: 'success'
                            });
                            this.getMonitorPage();
                        }).catch((error) => {
                            console.log(`保存失败，原因 => ${error}`)
                        });
                    }
                }
            });
        }
    }
}
</script>
