<template>
  <div class="app-container">
    <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
      <!--设备管理-->
      <el-tab-pane
        :label="$t('base.device.deviceManager')"
        :name="tempTab.tabName"
        :key="tempTab.tabName"
      >
        <el-form role="form" label-width="100px">
          <el-row>
            <el-col :span="6">
              <el-form-item :label="$t('base.company.name')">
                <el-select
                  @change="changeCompany"
                  v-model="device.listQuery.companyId"
                  @clear="clearEmpty('companyId')"
                  clearable
                  :style="{ width: '100%' }"
                >
                  <el-option
                    v-for="item in companyList"
                    :label="item.companyName"
                    :key="item.companyId"
                    :value="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--项目名称-->
              <el-form-item :label="$t('base.project.name')">
                <el-select
                  :style="{ width: '100%' }"
                  ref="project"
                  @change="changeProject"
                  clearable
                  v-model="device.listQuery.projectId"
                  :disabled="!device.listQuery.companyId"
                >
                  <el-option
                    v-for="item in projectList"
                    :label="item.projectName"
                    :value="item.projectId"
                    :key="item.projectId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--设备位置-->
              <el-form-item :label="$t('base.device.position')">
                <tree-select
                  v-model="device.listQuery.positionId"
                  :disable-branch-nodes="true"
                  :multiple="false"
                  :show-count="true"
                  :beforeClearAll="clearPositionId"
                  :placeholder="$t('commons.selectPlease')"
                  :noOptionsText="$t('base.category.notConfigured')"
                  :options="positionList"
                  @select="checkPositionId"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('base.category.system')">
                <!--所属系统-->
                <tree-select
                  v-model="device.listQuery.categoryId"
                  :multiple="false"
                  :show-count="true"
                  :beforeClearAll="clearCategoryId"
                  :placeholder="$t('commons.selectPlease')"
                  :noOptionsText="$t('base.category.notConfigured')"
                  :options="categoryList"
                  @select="checkCategoryId"
                />
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">
              <el-form-item :label="$t('base.device.name')">
                <el-input
                  v-model="device.listQuery.deviceName"
                  maxlength="25"
                  @keyup.enter.native="searchCondition"
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>-->
          </el-row>
          <el-row>
            <!--设备编号-->
            <!--<el-col :span="6">
              <el-form-item :label="$t('base.device.factoryCode')">
                <el-input
                  v-model="device.listQuery.factoryCode"
                  maxlength="50"
                  @keyup.enter.native="searchCondition"
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>-->
            <!--设备级别-->
            <!--<el-col :span="6">
              <el-form-item :label="$t('base.device.level')">
                <el-select
                  v-model="device.listQuery.deviceLevel"
                  :style="{ width: '100%' }"
                  @change="searchCondition"
                  clearable
                >
                  <el-option
                    :value="1"
                    :label="$t('commons.one_level')"
                  ></el-option>
                  <el-option
                    :value="2"
                    :label="$t('commons.two_level')"
                  ></el-option>
                  <el-option
                    :value="3"
                    :label="$t('commons.three_level')"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>-->
            <el-col :span="6">
              <el-form-item label="设备类型">
                <el-select
                  v-model="device.listQuery.deviceType"
                  :style="{ width: '100%' }"
                  clearable
                >
                  <el-option
                    v-for="(it, dex) in deviceType"
                    :label="it.itemName"
                    :value="it.itemCode"
                    :key="dex"
                  >
                    <span style="float: left">{{ it.itemName }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ it.itemCode }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">
              <el-form-item label="智能设备">
                <el-select
                  v-model="device.listQuery.isSmartDevice"
                  :style="{ width: '100%' }"
                  clearable
                >
                  <el-option value="Y" label="是"></el-option>
                  <el-option value="N" label="否"></el-option>
                </el-select>
              </el-form-item>
            </el-col>-->
            <el-col :span="6">
              <el-form-item label="设备">
                <el-input
                  v-model="device.listQuery.searchPhrase"
                  maxlength="50"
                  placeholder="请输入设备名称或编码"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button
                @click="searchCondition"
                type="primary"
                icon="el-icon-search"
                :loading="device.listLoading"
                :style="{ marginLeft: '30px' }"
                >{{ $t("commons.search") }}</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-download"
                plain
                @click="exportDevice"
                >{{ $t("commons.export") }}</el-button
              >
            </el-col>
          </el-row>
        </el-form>
        <div class="filter-container">
          <el-button
            type="success"
            icon="el-icon-plus"
            size="mini"
            @click="createDevice"
            plain
            >{{ $t("commons.add") }}</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="batchDelete"
            plain
            >{{ $t("commons.delete") }}</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-printer"
            size="mini"
            plain
            @click="openPrintModal"
            >{{ $t("commons.print") }}</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-upload"
            size="mini"
            plain
            @click="downDeviceTemplate"
            >{{ $t("commons.importTemplate") }}</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-upload2"
            size="mini"
            plain
            @click="uploadModel = true"
            >{{ $t("commons.import") }}</el-button
          >
        </div>
        <el-table
          v-loading="device.listLoading"
          :data="device.list"
          element-loading-text="loading"
          border
          fit
          highlight-current-row
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="deviceId"
            fixed
            type="selection"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column prop="projectName" :label="$t('base.project.name')">
            <template slot-scope="scope">
              <span>{{ scope.row.projectName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="deviceName"
            :label="$t('base.device.name')"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.deviceName }}</span>
              <el-tag
                v-if="scope.row.isSmartDevice && scope.row.isSmartDevice == 'Y'"
                class="rowTag"
                type="primary"
                size="mini"
                >智能</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="factoryCode" label="设备编码">
            <template slot-scope="scope">
              <span>{{ scope.row.factoryCode }}</span>
            </template>
          </el-table-column>
          <!--ych 设备优化剔除PK DN-->
          <!--<el-table-column
            prop="productKey"
            label="产品标识码"
          ></el-table-column>
          <el-table-column
            prop="rlinkDeviceName"
            label="设备标识码"
          ></el-table-column>-->
          <el-table-column prop="descName" label="安装位置"></el-table-column>
          <el-table-column
            prop="enterpriseName"
            label="关联企业"
            show-overflow-tooltip
            width="150"
          ></el-table-column>
          <el-table-column
            prop="dutyDeptName"
            label="责任部门"
          ></el-table-column>
          <el-table-column  label="设备类型">
            <template slot-scope="scope">
              <span>{{ getDeviceTypeStr(scope.row) }}</span>
            </template>
          </el-table-column>
          <!--<el-table-column prop="deviceLevel" :label="$t('base.device.level')">
            <template slot-scope="scope">
              <span>
                <template v-if="scope.row.deviceLevel == 1">
                  {{ $t("commons.one_level") }}
                </template>
                <template v-if="scope.row.deviceLevel == 2">
                  {{ $t("commons.two_level") }}
                </template>
                <template v-if="scope.row.deviceLevel == 3">
                  {{ $t("commons.three_level") }}
                </template>
              </span>
            </template>
          </el-table-column>-->
          <!--<el-table-column :label="$t('base.device.position')">
            <template slot-scope="scope">
              <span>{{ scope.row.descName }}</span>
            </template>
          </el-table-column>-->
          <el-table-column :label="$t('base.category.system')">
            <template slot-scope="scope">
              <span>{{ scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('commons.lastUpdateDate')">
            <template slot-scope="scope">
              <i class="el-icon-time" />
              <span v-if="scope.row.lastUpdateDate != null">
                {{
                  scope.row.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss")
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="lastUpdatedByName"
            label="更新人"
          ></el-table-column>
          <el-table-column
            fixed="right"
            :label="$t('commons.actions')"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="tbRowDetail(scope.row)"
                >详情</el-button
              >
              <el-button type="text" @click="updateDevice(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="copyDevice(scope.row)"
                >复制</el-button
              >
              <!--<el-button type="text"  @click="openMonitorModal(scope.row)">监控点管理</el-button>-->
              <el-button type="text" @click="printById(scope.row.deviceId)"
                >打印</el-button
              >
              <el-button type="text" @click="deleteDevice(scope.row.deviceId)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="device.total > 0"
          :total="device.total"
          :page.sync="device.listQuery.current"
          :limit.sync="device.listQuery.rowCount"
          @pagination="getDevicePage"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="editTab"
        :label="$t('base.device.monitorManager')"
        :name="editTab.tabName"
        :key="editTab.tabName"
        closable
      >
        <device-monitor
          v-bind:deviceRow="monitor"
          :key="monitor.deviceId"
        ></device-monitor>
      </el-tab-pane>

      <el-tab-pane
        v-if="detailTab.isShow"
        label="设备详情"
        :name="detailTab.tabVal"
        key="3"
        closable
      >
        <tabDeatail :datas="detailTab.datas" />
      </el-tab-pane>
    </el-tabs>

    <!--编辑设备弹框  新增-->
    <el-dialog
      :visible.sync="device.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="50%"
      class='diyModel'
      :close-on-click-modal="false"
      top="6vh"
    >
      <template slot="title"> <title-icon />{{ device.title }}</template>
      <div v-loading="dialogReqing">
        <!--<div>
          <el-radio-group
            v-model="device.form.isSmartDevice"
            style="margin-bottom: 10px"
            @change="tipsSetOrdinary"
          >
            <el-radio-button label="N">普通设备</el-radio-button>
            <el-radio-button label="Y">智能设备</el-radio-button>
          </el-radio-group>
        </div>-->

        <el-form
          label-position="top"
          ref="deviceForm"
          :rules="deviceFormRules"
          :model="device.form"
          label-width="100px"
        >
          <el-row :gutter="60" class="diyRow">
            <el-col :span="12">
              <!--设备名称-->
              <el-form-item
                :label="$t('base.device.name')"
                prop="deviceName"
                style="display: inline"
              >
                <el-input
                  type="hidden"
                  class="hidden"
                  v-model="device.form.deviceId"
                ></el-input>
                <el-input
                  v-model="device.form.deviceName"
                  maxlength="50"
                  :show-word-limit="true"
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                  @input="changeDeviceName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--设备编码-->
              <el-form-item
                :label="$t('base.device.factoryCode')"
                prop="factoryCode"
              >
                <el-input
                  v-model="device.form.factoryCode"
                  maxlength="50"
                  :show-word-limit="true"
                  oninput="value=value.replace(/\s+/g,'')"
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--项目名称-->
              <el-form-item label="所属项目" prop="projectId">
                <el-select
                  :style="{ width: '100%' }"
                  @change="changeAddProject"
                  clearable
                  v-model="device.form.projectId"
                >
                  <el-option
                    v-for="item in projectList"
                    :label="item.projectName"
                    :value="item.projectId"
                    :key="item.projectId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--设备位置-->
              <el-form-item
                :label="$t('base.device.position')"
                prop="positionId"
              >
                <tree-select
                  v-model="device.form.positionId"
                  @select="selectPosition"
                  :disable-branch-nodes="true"
                  :multiple="false"
                  :show-count="true"
                  :autoFocus="false"
                  :placeholder="$t('commons.selectPlease')"
                  :noOptionsText="$t('base.category.notConfigured')"
                  :options="addPositionList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--所属系统-->
              <el-form-item
                :label="$t('base.category.system')"
                prop="categoryId"
                style="display: inline"
              >
                <tree-select
                  v-model="device.form.categoryId"
                  :multiple="false"
                  :show-count="true"
                  :autoFocus="false"
                  :placeholder="$t('commons.selectPlease')"
                  :options="categoryList"
                  :noOptionsText="$t('base.category.notConfigured')"
                />
              </el-form-item>
            </el-col>
            <!--<template v-if="device.form.isSmartDevice == 'Y'">
              <el-col :span="12">
                <el-form-item label="产品标识码" prop="productKey">
                  <el-input
                    v-model="device.form.productKey"
                    :placeholder="$t('commons.pleaseInput')"
                    clearable
                    maxlength="20"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="设备标识码"
                  prop="rlinkDeviceName"
                  style="display: inline"
                >
                  <el-input
                    v-model="device.form.rlinkDeviceName"
                    :placeholder="$t('commons.pleaseInput')"
                    clearable
                    maxlength="20"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </template>-->
            <el-col :span="12">
              <!--设备类型-->
              <el-form-item :label="$t('base.device.type')" prop="deviceType">
                <el-select
                  v-model="device.form.deviceType"
                  class="maxWidth"
                  @change="deviceTypeChange"
                  clearable
                  :placeholder="$t('commons.selectPlease')"
                >
                  <el-option
                    v-for="item in deviceType"
                    :label="item.itemName"
                    :value="item.itemCode"
                    :key="item.itemCode"
                  >
                    <span style="float: left">{{ item.itemName }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.itemCode }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--关联企业-->
              <el-form-item
                label="关联企业"
                prop="dutyDeptId"
                style="display: inline"
              >
                <el-select
                  v-model="device.form.enterpriseId"
                  class="maxWidth"
                  clearable
                  :placeholder="$t('commons.selectPlease')"
                >
                  <el-option
                    v-for="item in enterpriseArrs"
                    :label="item.enterpriseName"
                    :value="item.enterpriseId"
                    :key="item.enterpriseId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--责任部门-->
              <el-form-item
                :label="$t('base.device.dutyDept')"
                prop="dutyDeptId"
              >
                <el-select
                  v-model="device.form.dutyDeptId"
                  class="maxWidth"
                  clearable
                  :placeholder="$t('commons.selectPlease')"
                >
                  <el-option
                    v-for="item in dutyDeptList"
                    :label="item.deptName"
                    :value="item.deptId"
                    :key="item.deptId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--负责人-->
              <el-form-item :label="$t('base.device.principal')">
                <el-select
                  v-model="device.form.repairPerson"
                  class="maxWidth"
                  clearable
                  :placeholder="$t('commons.selectPlease')"
                >
                  <el-option
                    v-for="item in employeeList"
                    :key="item.userId"
                    :label="item.userName"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!--维修工时-->
              <el-form-item
                :label="$t('base.category.workTime')"
                prop="workTime"
              >
                <el-input
                  v-model="device.form.workTime"
                  type="number"
                  @input="
                    if (device.form.workTime > 999) device.form.workTime = 999;
                    if (device.form.workTime < 0) device.form.workTime = 0;
                  "
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="$t('base.device.label')">
                <tag-bind
                  :tagValueList="device.form.tagList"
                  :maxlength="6"
                  :limit="20"
                  tagType="device"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
          	<!--经纬度 打开百度地图-->
            <el-col>
              <el-form-item :label="$t('base.project.location')">
                <el-input
                  :readonly="true"
                  v-model="device.form.location"
                  class="input-with-select"
                  :placeholder="$t('commons.selectPlease')"
                >
                  <el-button
                    slot="append"
                    @click="openMapSelect"
                    icon="el-icon-location"
                  ></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="btmBtn">
          <el-button @click="cancelDeviceSave">{{
            $t("commons.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="handleDeviceSave"
            :disabled="isHttping"
            v-loading.fullscreen.lock="device.createLoading"
            >{{ $t("commons.save") }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!--导入文件-->
    <el-dialog :visible.sync="uploadModel" width="40%">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="uploadType"
          :excelType="excelType"
          :rootPath="rootPath"
          @success="uploadSuccess"
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>

    <!--设备打印弹框-->
    <el-dialog
      :visible.sync="device.printDialogVisible"
      width="660px"
      top="6vh"
    >
      <template slot="title">
        <title-icon />{{ $t("commons.qrCode") }}
      </template>
      <div id="deviceLabelPrint">
        <template v-for="item in deviceLabelList">
          <div :key="item.deviceId" class="deviceQRItem">
            <vue-qr :text="item.qrText" :size="200"></vue-qr>
            <p class="deviceQRTitle">{{ item.deviceName }}</p>
          </div>
        </template>
      </div>
      <div slot="footer">
        <el-button @click="cancelPrintDevice">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          v-print="{id: 'deviceLabelPrint',popTitle: '打印内容'}"
          v-loading.fullscreen.lock="device.createLoading"
          >{{ $t("commons.print") }}
        </el-button>
      </div>
    </el-dialog>
		<div v-show="mapSelectDialog">
			<map-select
	      :position="position"
	      cityName="深圳"
	      :actions="isAction"
	      :mapSelectDialog="mapSelectDialog"
	    />
			
		</div>
    
  </div>
</template>

<script>
import {
  getDevicePage,
  getLoopUpItem,
  createDevice,
  updateDevice,
  deleteDeviceById,
  batchDeleteDevice,
  exportDevice,
  downDeviceTemplate,
  getPositionTree,
  findDeviceById,
  getDeviceIconByType,
  getDeptByProject,
} from "@/api/business/base/tenant/device";

import { getCompanyList } from "@/api/business/order/tenant/order";
import { getCategoryList } from "@/api/business/base/tenant/category";
import { whetherOpenModule } from "@/api/ruge/modulx";
import { findEmployee } from "@/api/ruge/tenant/employee";
import { getProjectList } from "@/api/business/base/tenant/project";
import TagBind from "@/components/TagBind";
import Pagination from "@/components/Pagination";
import DeviceMonitor from "@/views/business/base/device/tenant/deviceMonitor";
import { listToTree } from "@/utils/tree";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
import vueQr from "vue-qr";
import store from "@/store/index";
import pinyin from "js-pinyin";
import tabDeatail from "./tabDeatail.vue";
import MapSelect from "@/components/MapSelect";
const defineTab = {
  tempTab: {
    tabName: "1",
  },
  editTab: {
    tabName: "2",
  },
};

export default {
  name: "DeviceManager",
  components: {
    DeviceMonitor,
    Pagination,
    TreeSelect,
    Upload,
    vueQr,
    TagBind,
    tabDeatail,
    MapSelect,
  },
  data() {
    return {
      position: null,
      isAction: true,
      mapSelectDialog: false,
      // 默认设备图标
      defaultDeviceIcon:
        'this.src="' + require("@/assets/images/monitor.png") + '"',
      activeTabName: defineTab.tempTab.tabName,
      editTab: null,
      companyList: [], //公司options
      projectList: [],
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload",
      uploadModel: false,
      uploadType: "ExcelImport",
      excelType: "ruge.device",
      rootPath: envInfo.bgApp.basePath,
      positionList: [],
      addPositionList: [],
      dutyDeptList: [],
      serveTypeArrs: [],
      categoryList: [],
      deviceLabelList: [],
      deviceMap: null,
      openDelay: 1000,
      employeeList: [],
      deviceType: [],
      deviceNamesObj: {}, //设备类型名称集合
      schemaList: [],
      detailTab: {
        isShow: false,
        tabVal: "serveDetail",
        datas: {},
      },
      editRowData: {}, //编辑的时候数据
      enterpriseArrs: [], //关联企业options
      device: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          companyId: null,
          projectId: null,
          deviceName: null,
          positionId: null,
          categoryId: null,
          factoryCode: null,
          deviceLevel: null,
          isSmartDevice: null,
          deviceType: null,
          searchPhrase: null,
        },
        form: {
          //        deviceId: null,
          //        deviceName: null,
          //        deviceLevel: 1,
          //        positionId: null,
          //        positionName: null,
          //        projectId: null,
          //        categoryId: null,
          //        categoryName: null,
          //        deviceEui: null,
          //        protocol: null,
          //        workTime: null,
          //        isCopy: false,
          //        factoryCode: null,
          //        repairPerson: "",
          //        dutyDeptId: null,
          //        repairPersonName: null,
          //        deviceType: null,
          //        startPhoto: null,
          //        stopPhoto: null,
          //        tagList: [],
          //        productKey:null,//产品标识码
          //        rlinkDeviceName:null,//设备标识码
        },
        multipleSelection: [],
        addDialogVisible: false,
        createLoading: false,
        printDialogVisible: false,
        editMonitorDialogVisible: false,
      },
      monitor: {
        deviceId: null,
        deviceName: null,
        categoryName: null,
      },
      deviceFormRules: {
        deviceName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        factoryCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        deviceLevel: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        positionId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        deviceEui: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        protocol: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        projectId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        categoryId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        //改成非必填
//      deviceType: [
//        {
//          required: true,
//          trigger: ["blur", "change"],
//          message: this.$t("validate.required"),
//        },
//      ],

        productKey: [
          //产品标识码
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        rlinkDeviceName: [
          //设备标识码
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        // workTime: [
        //     {
        //         required: true,
        //         trigger: ['blur', 'change'],
        //         message: this.$t('validate.required')
        //     }
        // ],
      },
      dialogReqing: false, //打开弹窗的时候是否在请求数据
      isHttping: false,
      isJumped:false,//标识是否从别处跳转页面过来 打开过详情页
    };
  },
  created() {
    this.clearDeviceForm(); //页面未创建之前 init 该数据
    this.searchCondition();
    this.getCategoryList();
    //this.getPositionList();
    this.findEmployeeList();
    this.findSchemaList();
    this.findDeviceType();
    this.getCompanyList(); //获取公司信息
    this.getProjectList();
  },
  mounted() {
    this.$eventBus.$off("importSuccess");
    this.$eventBus.$on("importSuccess", () => {
      this.searchCondition();
    });
  },
  computed: {
    tempTab() {
      return defineTab.tempTab;
    },
  },
  methods: {
  	byUrlToDetail(){
  		if(!this.isJumped){
  			this.$nextTick(()=>{
		    	setTimeout(()=>{
		    		const {rowId}=this.$route.query;
			    	console.log('rowId---------',rowId,this.device.list)
			    	if(rowId){
			    		for(let it of this.device.list)
			    		 if(it.deviceId==rowId){
			    		 	this.isJumped=true;
			    		 	this.tbRowDetail(it);
			    		 	break;
			    		 }
			    	}
		    	},300)
		    })
  		}
  		
  	},
    selectMapPosition(position) {
      this.device.form.location = position.lng + "," + position.lat;
      this.mapSelectDialog = false;
    },

    openMapSelect() {
      //设置为可操作
      this.isAction = true;
      if (this.device.form.location != null) {
        this.position = {
          lng: parseFloat(this.device.form.location.split(",")[0]),
          lat: parseFloat(this.device.form.location.split(",")[1]),
        };
      } else {
        this.position = null;
      }
      this.mapSelectDialog = true;
    },
    openMapLocation(row) {
      if (row.location) {
        //设置为不可操作
        this.isAction = false;
        this.position = {
          lng: parseFloat(row.location.split(",")[0]),
          lat: parseFloat(row.location.split(",")[1]),
        };
        this.mapSelectDialog = true;
      } else {
        this.$message({
          message: this.$t("base.project.locationNotExist"),
          type: "info",
        });
      }
    },

    deviceTypeChange() {
      if (this.device.form.deviceType) {
        getDeviceIconByType({ deviceType: this.device.form.deviceType }).then(
          (res) => {
            this.device.form.startPhoto = res.startPhoto;
            this.device.form.stopPhoto = res.stopPhoto;
          }
        );
      } else {
        this.device.form.startPhoto = null;
        this.device.form.stopPhoto = null;
      }
    },
    getDeviceIcon(fileId) {
      if (fileId) {
        return (
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          fileId
        );
      }
      return "";
    },
    // 关闭项Tab
    removeTab(tabName) {
      console.log("tabName", tabName);
      this.activeTabName = defineTab.tempTab.tabName;
      if (tabName == "2") {
        this.editTab = null;
      }
      if (tabName == this.detailTab.tabVal) {
        this.detailTab.isShow = false;
      }
    },
    getCompanyList() {
      getCompanyList().then((res) => {
        this.companyList = res;
      });
    },
    getProjectList(companyId) {
      getProjectList({ companyId: companyId }).then((res) => {
        this.projectList = res;
      });
    },
    changeCompany(companyId) {
      if (companyId) {
        this.getProjectList(companyId);
      } else {
        this.device.listQuery.projectId = null;
        this.device.listQuery.positionId = null;
        this.projectList = [];
        this.positionList = [];
      }
      console.log("切换公司ID", companyId, this.projectList);
    },
    changeProject() {
      if (this.device.listQuery.projectId == "") {
        this.device.listQuery.projectId = null;
        this.device.listQuery.positionId = null;
        this.positionList = [];
      } else {
        getPositionTree({ projectId: this.device.listQuery.projectId }).then(
          (response) => {
            this.device.listQuery.positionId = null;
            this.positionList = [];
            let data = listToTree(response, "positionId", "parentId");
            this.recursivePosition(data);
          }
        );
      }
    },
    //递归位置列表修改属性及数据结构
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.positionList = data;
    },
    async initEnterpriseArrs() {
      this.device.form.enterpriseId = null;
      //获取企业列表
      let params = {
        current: 1,
        rowCount: 99999,
        projectId: this.device.form.projectId,
      };
      let res = await this.ApiHttp("/organization/enterprise/list", params);
      console.log("initTable", res);
      if (res) {
        res.map((it) => {
          if (it.enterpriseName.length > 20) {
            it.enterpriseName = it.enterpriseName.slice(0, 20) + "...";
          }
          it.code = it.enterpriseId;
        });
      }
      this.enterpriseArrs = res || [];
    },
    changeAddProject() {
      if (!this.device.form.projectId) return;
      this.initEnterpriseArrs();
      getDeptByProject({ projectId: this.device.form.projectId }).then(
        (res) => {
          this.dutyDeptList = res;
        }
      );
      if (this.device.form.projectId == "") {
        this.device.form.projectId = null;
        this.device.form.positionId = null;
        this.addPositionList = [];
      } else {
        return getPositionTree({ projectId: this.device.form.projectId }).then(
          (response) => {
            this.device.form.positionId = null;
            this.addPositionList = [];
            let data = listToTree(response, "positionId", "parentId");
            this.recursiveAddPosition(data);
          }
        );
      }
    },
    //递归位置列表修改属性及数据结构
    recursiveAddPosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursiveAddPosition(v.children);
        }
        return v;
      });
      this.addPositionList = data;
    },
    checkPositionId(val) {
      this.device.listQuery.positionId = val.positionId;
      this.searchCondition();
    },
    clearPositionId() {
      this.device.listQuery.positionId = "";
      return true;
    },
    clearCategoryId() {
      this.device.listQuery.categoryId = "";
      return true;
    },
    checkCategoryId(val) {
      this.device.listQuery.categoryId = val.categoryId;
      this.searchCondition();
    },
    searchCondition() {
      this.device.listLoading = true;
      this.device.listQuery.current = 1;
      this.getDevicePage();
    },
    getDevicePage() {
      this.device.listLoading = true;
      let params = { ...this.device.listQuery };
      for (let keys in params) {
        if (!params[keys]) params[keys] = null;
      }
      getDevicePage(params).then((response) => {
        this.device.total = response.total;
        this.device.list = response.rows;
        let deviceMap = new Map();
        this.device.list.map((v) => {
          deviceMap.set(v.deviceId, v);
        });
        this.deviceMap = deviceMap;
        this.device.listLoading = false;
        this.byUrlToDetail();//执行判断下是否从别处跳转过来 并且需要默认打开详情页
      });
    },
    findEmployeeList() {
      findEmployee().then((response) => {
        this.employeeList = response;
      });
    },
    findSchemaList() {
      getLoopUpItem({ classifyCode: "COMMUNICATION_SCHEMA" }).then((res) => {
        this.schemaList = res;
      });
    },
    findDeviceType() {
      getLoopUpItem({ classifyCode: "DEVICE_TYPE" }).then((res) => {
        res.map((it) => (this.deviceNamesObj[it.itemCode] = it.itemName));
        this.deviceType = res;
      });
    },
    getCategoryList() {
      getCategoryList().then((response) => {
        let data = listToTree(response, "categoryId", "parentId");
        this.recursiveCategory(data);
      });
    },
    selectPosition(node) {
      this.device.form.positionId = node.positionId;
    },
    //递归系统列表修改属性及数据结构
    recursiveCategory(row) {
      let data = row.map((v) => {
        v.label = v.categoryName;
        v.id = v.categoryId;
        if (v.children != undefined) {
          this.recursiveCategory(v.children);
        }
        return v;
      });
      this.categoryList = data;
    },
    handleSelectionChange(data) {
      let deviceIds = [];
      data.forEach(function (obj) {
        deviceIds.push(obj.deviceId);
      });
      this.device.multipleSelection = deviceIds;
    },
    cancelDeviceSave() {
      this.device.addDialogVisible = false;
    },
    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          //去重复
          this.device.form.tagList = Array.from(
            new Set(this.device.form.tagList)
          );
          let reqParams = { ...this.device.form };
          if (reqParams.isSmartDevice == "N") {
            delete reqParams.productKey;
            delete reqParams.rlinkDeviceName;
          }
          this.isHttping = true;
          setTimeout(() => {
            this.isHttping = false;
          }, 2000);
          if (
            this.device.form.deviceId == null ||
            this.device.form.isCopy == true
          ) {
            createDevice(reqParams)
              .then(() => {
                this.device.addDialogVisible = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.getDevicePage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            updateDevice(reqParams)
              .then(() => {
                this.device.addDialogVisible = false;
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.getDevicePage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    createDevice() {
      this.addPositionList = [];
      this.enterpriseArrs = [];
      this.device.title = this.$t("base.device.add");
      this.clearDeviceForm();
      this.device.addDialogVisible = true;
    },
    deleteDevice(deviceId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteDeviceById({ deviceId: deviceId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.searchCondition();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    batchDelete() {
      if (this.device.multipleSelection.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
      } else {
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            batchDeleteDevice({
              deviceIds: this.device.multipleSelection.join(","),
            }).then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.searchCondition();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    tbRowDetail(row) {
      row.deviceNamesObj = { ...this.deviceNamesObj };
      this.detailTab.datas = { ...row };
      this.detailTab.isShow = true;
      this.activeTabName = this.detailTab.tabVal;
    },
    updateDevice(row) {
      this.device.addDialogVisible = true;
      this.dialogReqing = true;
      findDeviceById({ deviceId: row.deviceId }).then((res) => {
        this.device.title = this.$t("base.device.edit");
        this.clearDeviceForm();
        this.device.form.projectId = row.projectId;
        this.changeAddProject().then(() => {
          getDeptByProject({ projectId: res.projectId }).then((list) => {
            this.dutyDeptList = list;
            if (!res.isSmartDevice) res.isSmartDevice = "N";
            this.editRowData = JSON.parse(JSON.stringify(res));
            this.device.form = res;
            this.device.form.isCopy = false;
            this.dialogReqing = false;
          });
        });
      });
    },
    copyDevice(row) {
      this.dialogReqing = true;
      this.device.addDialogVisible = true;
      findDeviceById({ deviceId: row.deviceId }).then((res) => {
        this.device.title = this.$t("base.device.copy");
        this.clearDeviceForm();
        this.device.form.projectId = row.projectId;
        this.changeAddProject().then(() => {
          getDeptByProject({ projectId: res.projectId }).then((list) => {
            this.dutyDeptList = list;
            this.device.form = res;
            this.device.form.deviceId = null;
            this.device.form.isCopy = true;
            this.dialogReqing = false;
          });
        });
      });
    },
    clearDeviceForm() {
      this.device.form = {
        isSmartDevice: "N", //是否智能设备
        deviceId: null,
        deviceName: null,
        deviceLevel: 1,
        positionId: null,
        positionName: null,
        categoryId: null,
        categoryName: null,
        isCopy: false,
        workTime: null,
        factoryCode: null,
        repairPerson: null,
        repairPersonName: null,
        deviceType: null,
        startPhoto: null,
        stopPhoto: null,
        errorPhoto: null,
        tagList: [],
        location: null,
        productKey: null, //产品标识码
        rlinkDeviceName: null, //设备标识码
        enterpriseId: null, //关联企业
      };
      if (this.$refs.deviceForm) {
        this.$refs.deviceForm.resetFields();
      }
    },
    uploadStartDeviceSuccess(files) {
      this.device.form.startPhoto = files[0].fileId;
      // this.startUploadModel = false
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
    },
    uploadStopDeviceSuccess(files) {
      this.device.form.stopPhoto = files[0].fileId;
      // this.stopUploadModel = false
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
    },
    uploadSuccess() {
      this.uploadModel = false;
      // this.$message({
      //   type: "success",
      //   message: this.$t("message.uploadSuccess"),
      // });
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    cancelPrintDevice() {
      this.device.printDialogVisible = false;
    },
    openPrintModal() {
      if (this.device.multipleSelection.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
      } else {
        let deviceLabelList = [];
        this.device.multipleSelection.forEach((v) => {
          let obj = this.deviceMap.get(v);
          obj.qrText = `{"type":"DEVICE","deviceId":` + obj.deviceId + `}`;
          deviceLabelList.push(obj);
        });
        this.deviceLabelList = deviceLabelList;
        this.device.printDialogVisible = true;
      }
    },
    printById(deviceId) {
      let deviceLabelList = [];
      let obj = this.deviceMap.get(deviceId);
      obj.qrText = `{"type":"DEVICE","deviceId":` + obj.deviceId + `}`;
      deviceLabelList.push(obj);
      this.deviceLabelList = deviceLabelList;
      this.device.printDialogVisible = true;
    },
    exportDevice() {
      exportDevice(this.device.listQuery).then((msg) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId: msg,
          taskName: "Device",
          taskStatus: 0,
          rootPath: "basePath",
        };
        //将导出任务丢入导出任务列表中
        store.dispatch("PushExportNotice", exportObj);
      });
    },
    downDeviceTemplate() {
      downDeviceTemplate();
    },
    openMonitorModal(row) {
      whetherOpenModule({ moduleCode: "iot" }).then((status) => {
        if (status > 0) {
          this.editTab = defineTab.editTab;
          this.monitor.deviceId = row.deviceId;
          this.monitor.deviceName = row.deviceName;
          this.monitor.categoryName = row.categoryName;
          this.activeTabName = defineTab.editTab.tabName;
        } else {
          this.$message({
            type: "warn",
            message: this.$t("base.device.message.unOpenIOT"),
          });
        }
      });
    },
    // 联动设备编号
    changeDeviceName(val) {
    	let  getCode=pinyin.getCamelChars(val);
      this.device.form.factoryCode = this.common.stringTrim(getCode);
    },
    getDeviceTypeStr(row) {
      return this.deviceNamesObj[row.deviceType];
    },
    tipsSetOrdinary(val) {
      //智能设备修改为普通设备的时候提示
      console.log("val", val, this.device.form.isSmartDevice);
      if (this.editRowData.isSmartDevice == "N") return;
      if (
        val == "N" &&
        this.device.title == "编辑设备" &&
        !this.device.form.isCopy
      ) {
        this.$confirm("是否确定修改为普通设备?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {
            this.device.form.isSmartDevice = "Y";
          });
      }
    },
    clearEmpty(field) {
      this.device.listQuery[field] = null;
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.maxWidth {
  width: 100%;
}

.deviceQRItem {
  display: inline-grid;
}

.deviceQRTitle {
  width: 200px;
  padding: 0px 16px;
  text-align: center;
  margin: 0 auto;
}

.upload-demo >>> .el-upload-dragger {
  width: 55px;
  height: 55px;
}
::v-deep .el-input__inner {
  line-height: 1px !important;
}
.rowTag {
  margin-left: 8px;
}
</style>
<style type="text/css">
.vue-treeselect__menu-container {
  z-index: 999999999 !important;
}
</style>